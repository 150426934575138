<template>
  <QuizOneQuestionEditView disabled />
</template>

<script>
import QuizOneQuestionEditView from '@/views/quiz/QuizOneQuestionEditView'

export default {
  name: 'QuizOneQuestionView',
  components: {
    QuizOneQuestionEditView
  }
}
</script>
